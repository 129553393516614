import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SearchAirports from './SearchAirports';
import { OAButton, OAChip, OAEmpty, OAImage } from '../../components';
import SelectTravellers from './SelectTravelllers';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { setTravelDetail } from '../../store/slices/userInputSlice';
import { useNavigate } from 'react-router-dom';
import TravelDate from './TravelDate';
import { isFuture, isToday, parseISO } from 'date-fns';
import { getAuthInfo } from '../../store/slices/authSlice';
import { setFilter, setSort } from '../../store/slices/searchFlightSlice';
import { eventsTracker } from '../../utils/ctEventsTracking';
import OASwitchComponent from '../../components/OASwitchComponent';
import { usePostHog } from 'posthog-js/react';
import TabSwitchSkeleton from '../SearchResult/TabSwitchSkeleton';
import { setMessage } from '../../store/slices/snackbarSlice';
import { isMobileWebview } from '../../utils';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { useLazyNearbyAirportQuery } from '../../services/airportDataApi';

const SearchParameters = ({
  isEditing = false,
  toggleDrawer,
  shouldFetchLocations = false,
  isEditDrawerOpen,
  isUpdatingResults,
  setCurrentDirection,
  setFilterData,
  filterData,
  setIsResultsLoading,
  setScrollTop,
}: any) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fetchNearbyAirport] = useLazyNearbyAirportQuery();
  const { travelDetail } = useSelector<any>(getUserInputState);
  const { user, token } = useSelector(getAuthInfo);
  const { studentFareFeat } = useSelector(getFeatureFlag);
  const [startTime, setStartTime] = useState(Date.now());
  const [returnTime, setReturnTime] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [startEditingTime, setStartEditingTime] = useState(Date.now());
  const [localTravelDetail, setLocalTravelDetail] = useState<any>({
    from: {
      iata: travelDetail?.from?.iata ?? null,
      city: travelDetail?.from?.city ?? null,
      name: travelDetail?.from?.name ?? null,
    },
    to: {
      iata: travelDetail?.to?.iata ?? null,
      city: travelDetail?.to?.city ?? null,
      name: travelDetail?.to?.name ?? null,
    },
    tripStart: {
      date: travelDetail?.tripStart?.date ?? null,
    },
    tripEnd: {
      date: travelDetail?.tripEnd?.date ?? null,
    },
    cabinType: travelDetail?.cabinType ?? '',
    travellerCount: {
      adult: travelDetail?.travellerCount?.adult ?? 0,
      child: travelDetail?.travellerCount?.child ?? 0,
      infant: travelDetail?.travellerCount?.infant ?? 0,
    },
    tripType: travelDetail?.tripType ?? 'O',
    fareGroup: travelDetail?.fareGroup ?? 'REGULAR',
  });

  const toggleLabels = ['One Way', 'Round Trip'];
  const convertDateToISO: any = (dt: string) => (dt ? parseISO(dt) : null);

  const handleSwapLocation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setLocalTravelDetail({
      ...localTravelDetail,
      from: localTravelDetail?.to,
      to: localTravelDetail?.from,
    });
  };

  const isValidRequest = (td: any): boolean => {
    // Check for required fields
    if (
      !td.tripStart?.date ||
      !td.cabinType ||
      !td.from?.iata ||
      !td.to?.iata ||
      !td.travellerCount?.adult
    ) {
      dispatch(setMessage('Field is missing!'));
      return false;
    }

    // Check if the trip start date is today or in the future
    const dateToCheck = parseISO(td.tripStart.date);
    if (!(isToday(dateToCheck) || isFuture(dateToCheck))) {
      dispatch(setMessage('Date should be today or future'));
      return false;
    }

    // Check if 'from' and 'to' iatas are the same
    if (td.from.iata === td.to.iata) {
      dispatch(setMessage('Departure and arrival city cannot be the same.'));
      return false;
    }

    if (
      td.fareGroup === 'STUDENT' &&
      (td.travellerCount?.child > 0 || td.travellerCount?.infant > 0)
    ) {
      dispatch(
        setMessage(
          'Student fares are available for adult passengers only. Please de-select any child/infant passengers to continue.'
        )
      );
      return false;
    }

    // If all checks pass
    return true;
  };

  const handleSetTravelDetail = (updatedDetails: any) => {
    setLocalTravelDetail((prevState: any) => ({
      ...prevState,
      ...updatedDetails,
    }));
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (isEditDrawerOpen) {
      setStartEditingTime(Date.now());
    }
  }, [isEditDrawerOpen]);

  const onSearch = () => {
    const isProduction = window.location.href.includes('goniyo.com') || window.location.href.includes('zolve');
    if (isProduction && isMobileWebview()) {
      posthog.startSessionRecording();
    }

    setIsResultsLoading && localTravelDetail !== travelDetail && setIsResultsLoading(true);

    if (filterData) {
      setFilterData(null);
    }
    if (isEditing) {
      setCurrentDirection(0);
    }
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    const totalEditingDuration = Math.floor((Date.now() - startEditingTime) / 1000);

    const totalTravelers =
      localTravelDetail?.travellerCount?.adult +
      localTravelDetail?.travellerCount?.child +
      localTravelDetail?.travellerCount?.infant;

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: isEditing ? 'SRP' : 'Home',
        ctaAction: isEditing ? 'Edit' : 'Search',
        screenDuration: isEditing
          ? totalEditingDuration?.toString()
          : totalScreenDuration?.toString(),
        otherData: {
          from: localTravelDetail?.from?.iata,
          to: localTravelDetail?.to?.iata,
          departureDate: localTravelDetail?.tripStart?.date,
          class: localTravelDetail?.cabinType,
          numberOfTravellers: totalTravelers,
        },
      },
      posthog
    );
    if (!isValidRequest(localTravelDetail)) {
      return;
    }
    dispatch(setTravelDetail(localTravelDetail));
    const tempTraveller = localTravelDetail?.travellerCount;
    const nullToZero = (value: any) => (value === null || value === undefined ? 0 : value);
    const formattedString = `adults=${nullToZero(tempTraveller.adult)}&childs=${nullToZero(
      tempTraveller.child
    )}&infants=${nullToZero(tempTraveller.infant)}`;
    const tripType = localTravelDetail?.tripEnd?.date ? 'R' : 'O';
    const cabinType = localTravelDetail?.cabinType;
    const traveller = formattedString;

    const fromTo =
      `${localTravelDetail?.from?.iata}` +
      `&fromCity=${localTravelDetail?.from?.city}` +
      `&to=${localTravelDetail?.to?.iata}` +
      `&toCity=${localTravelDetail?.to?.city}` +
      `&fromDate=${localTravelDetail?.tripStart?.date}` +
      `${localTravelDetail?.tripEnd?.date ? `&toDate=${localTravelDetail?.tripEnd?.date}` : ''}` +
      `${localTravelDetail?.fareGroup ? `&fareGroup=${localTravelDetail?.fareGroup}` : ''}`;

    const baseURL = isEditing ? `` : `results`;
    const searchURL = `${baseURL}?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`;
    // Clear filter & sort
    dispatch(setSort('plh'));
    dispatch(setFilter(null));
    navigate(searchURL);
    if (toggleDrawer) {
      toggleDrawer();
    }
  };

  useEffect(() => {
    if (shouldFetchLocations && travelDetail?.from?.iata === null) {
      fetchNearbyAirport({}).unwrap().then((data) => {
        setLocalTravelDetail((prevDetail: any) => ({
          ...prevDetail,
          from: data?.data?.from,
          to: data?.data?.to,
        }));
      }).catch((error) => {
        console.error('Error fetching nearby airport:', error);
      });
    }
  }, [shouldFetchLocations]);

  useEffect(() => {
    if (travelDetail?.from?.iata && travelDetail?.to?.iata) {
      setLocalTravelDetail((prevDetail: any) => ({
        ...prevDetail,
        from: {
          iata: travelDetail?.from?.iata,
          city: travelDetail?.from?.city,
          name: travelDetail?.from?.name,
        },
        to: {
          iata: travelDetail?.to?.iata,
          city: travelDetail?.to?.city,
          name: travelDetail?.to?.name,
        },
      }));
    }
  }, [travelDetail?.from, travelDetail?.to]);

  const handleChange = useCallback(
    (newValue: number) => {
      const tempValue = newValue === 0 ? 'O' : 'R';
      let updatedDate = {};

      const dateObject = new Date(localTravelDetail?.tripStart?.date);
      dateObject.setDate(dateObject.getDate() + 1);
      let endDate = dateObject.toISOString();

      if (tempValue === 'O') {
        setReturnTime(
          localTravelDetail?.tripEnd?.date
            ? new Date(localTravelDetail?.tripEnd?.date).toISOString()
            : null
        );
        updatedDate = {
          tripStart: {
            date: localTravelDetail?.tripStart?.date
              ? new Date(localTravelDetail?.tripStart?.date).toISOString()
              : null,
          },
          tripEnd: {
            date: null,
          },
        };
      } else {
        updatedDate = {
          tripStart: {
            date: localTravelDetail?.tripStart?.date
              ? new Date(localTravelDetail?.tripStart?.date).toISOString()
              : null,
          },
          tripEnd: {
            date: isEditing
              ? returnTime ?? endDate
              : null
              ? new Date(travelDetail?.tripEnd?.date).toISOString()
              : localTravelDetail?.tripEnd?.date
              ? new Date(localTravelDetail?.tripEnd?.date).toISOString()
              : returnTime ?? endDate,
          },
        };
      }

      const updatedTravelDetail: any = {
        ...localTravelDetail,
        tripType: tempValue,
        ...updatedDate,
      };
      setLocalTravelDetail(updatedTravelDetail);
    },
    [localTravelDetail]
  );

  useEffect(() => {
    setLocalTravelDetail(travelDetail);
  }, [isEditDrawerOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2);

    // cleanup function to clear timeout if component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setLocalTravelDetail(travelDetail);
  }, [isEditDrawerOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2);

    // cleanup function to clear timeout if component unmounts
    return () => clearTimeout(timer);
  }, []);

  const orgCode = user?.organizationCode;

  const handleFareGroup = () => {
    setLocalTravelDetail((prev: any) => ({
      ...prev,
      fareGroup: prev.fareGroup === 'REGULAR' ? 'STUDENT' : 'REGULAR',
    }));
  };

  return (
    <Box>
      {token ? (
        <>
          <Box sx={{ mt: !isEditing ? '0px' : '0px', pt: !isEditing ? '20px' : '0px' }}>
            {loading ? (
              <TabSwitchSkeleton orgCode={orgCode} />
            ) : (
              <OASwitchComponent
                value={localTravelDetail?.tripType === 'O' ? 0 : 1}
                onChange={handleChange}
                labels={toggleLabels}
                isLoading={loading}
                orgCode={orgCode}
              />
            )}
          </Box>
          <Box sx={{ mt: '20px' }}>
            <SearchAirports
              label="From"
              title="Select Departure"
              fieldId="departure"
              value={
                localTravelDetail?.from?.iata
                  ? `${localTravelDetail?.from?.iata}${
                      localTravelDetail?.from?.city ? ` - ${localTravelDetail?.from?.city}` : ''
                    }${localTravelDetail?.from?.name ? ` - ${localTravelDetail.from?.name}` : ''}`
                  : ''
              }
              localTravelDetail={localTravelDetail}
              onSelect={handleSetTravelDetail}
              orgCode={orgCode}
              setScrollTop={setScrollTop}
            />
          </Box>
          <Box sx={{ mt: '20px', position: 'relative' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                bgcolor: '#FDFDFD',
                width: '40px',
                height: '40px',
                border: '1px solid #C7CCCA',
                borderRadius: '50%',
                position: 'absolute',
                top: -27,
                right: 15,
                zIndex: 1,
              }}
              onClick={handleSwapLocation}
            >
              <OAImage
                src="swap-vert.svg"
                alt="Swap Vert"
                folder={orgCode === 'ZOLVE' ? 'zolveIcons' : 'icons'}
              />
            </Box>
            <SearchAirports
              label="To"
              title="Select Arrival"
              fieldId="to"
              value={
                localTravelDetail?.to?.iata
                  ? `${localTravelDetail.to.iata}${
                      localTravelDetail?.to?.city ? ` - ${localTravelDetail?.to?.city}` : ''
                    }${localTravelDetail?.to?.name ? ` - ${localTravelDetail?.to?.name}` : ''}`
                  : ''
              }
              onSelect={handleSetTravelDetail}
              localTravelDetail={localTravelDetail}
              orgCode={orgCode}
              setScrollTop={setScrollTop}
            />
          </Box>
          <Box sx={{ mt: '20px' }}>
            <TravelDate
              localTravelDetail={localTravelDetail}
              setLocalTravelDetail={setLocalTravelDetail}
              onSelect={handleSetTravelDetail}
              setCurrentDirection={setCurrentDirection}
              orgCode={orgCode}
            />
          </Box>
          <Box sx={{ mt: '20px' }}>
            <SelectTravellers
              localTravelDetail={localTravelDetail}
              onSelect={handleSetTravelDetail}
              orgCode={orgCode}
            />
          </Box>
          {orgCode === 'ZOLVE' && studentFareFeat && (
            <Box mt="24px">
              <OAChip
                label="Regular fare"
                icon={
                  <OAImage
                    src={
                      localTravelDetail?.fareGroup === 'REGULAR'
                        ? 'regular.svg'
                        : 'regular-light.svg'
                    }
                    alt="Land"
                    folder="zolveIcons"
                    sx={{ mr: '10px' }}
                  />
                }
                onClick={handleFareGroup}
                sx={{
                  background:
                    localTravelDetail?.fareGroup === 'REGULAR' ? 'black !important' : 'revert',
                  color: localTravelDetail?.fareGroup === 'REGULAR' ? '#FFFFFF' : 'text.secondary',
                }}
              />
              <OAChip
                label="Student fare"
                icon={
                  <OAImage
                    src={
                      localTravelDetail?.fareGroup === 'STUDENT' ? 'study.svg' : 'study-light.svg'
                    }
                    alt="study"
                    folder="zolveIcons"
                    sx={{ mr: '10px' }}
                  />
                }
                sx={{
                  ml: '12px',
                  background:
                    localTravelDetail?.fareGroup === 'STUDENT' ? 'black !important' : 'revert',
                  color: localTravelDetail?.fareGroup === 'STUDENT' ? '#FFFFFF' : 'text.secondary',
                }}
                onClick={handleFareGroup}
              />
            </Box>
          )}
          <OAButton
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onSearch}
            sx={{ mt: '30px', color: '#FFFFFF', fontWeight: 600 }}
            disabled={!token && !user?.id}
          >
            Search Flights
          </OAButton>
        </>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: '360px' }}>
          <OAEmpty
            illustration={
              <OAImage
                src="flight-unavailable.svg"
                folder={orgCode === 'ZOLVE' ? 'zolveErrorAssets' : 'niyoErrorAssets'}
                alt="unavailable"
              />
            }
            bodytext="Flight services are temporarily unavailable"
            bodySubtext="Please reach out to our support team for assistance & swift resolution"
          />
        </Box>
      )}
    </Box>
  );
};

export default SearchParameters;
