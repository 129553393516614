import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type FeaturesState = {
  burnCoinsFeat: boolean;
  earnCoinsFeat: boolean;
  fareCalendarFeat: boolean;
  exclusiveFare: boolean;
  chatbotSupport: boolean;
  smbFeat: boolean;
  srpBottomDrawerFeat: boolean;
  studentFareFeat: boolean;
};

// Initial state with default flags
const initialState: FeaturesState = {
  burnCoinsFeat: false,
  earnCoinsFeat: false,
  fareCalendarFeat: true,
  exclusiveFare: false,
  chatbotSupport: true,
  smbFeat: true,
  srpBottomDrawerFeat: false,
  studentFareFeat: true,
};

export const featuresSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setFeatureFlag: (state, action: PayloadAction<Partial<FeaturesState>>) => {
      Object.keys(action.payload).forEach((key) => {
        const safeKey = key as keyof FeaturesState; // Ensure the key is a valid key of FeaturesState
        if (state.hasOwnProperty(safeKey)) {
          state[safeKey] = action.payload[safeKey]!;
        }
      });
    },
    setBurnCoinsFeat: (state, action) => {
      state.burnCoinsFeat = true;
    },
    setSmbFeat: (state, action) => {
      state.smbFeat = action.payload;
    },
    setStudentFareFeat: (state, action) => {
      state.studentFareFeat = action.payload;
    },
  },
});

export const { setFeatureFlag, setBurnCoinsFeat, setSmbFeat, setStudentFareFeat } =
  featuresSlice.actions;

export const getFeatureFlag = (state: { features: any }) => state.features;

export default featuresSlice.reducer;
